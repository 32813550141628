<template>
  <div>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      @submit.prevent="login"
    >
      <v-card
        class="mx-auto my-12"
        max-width="374"
      >
        <v-card-title>Login</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            required
          ></v-text-field>
          <v-text-field
            v-model="password"
            :append-icon="showPw ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="showPw ? 'text' : 'password'"
            name="input-10-1"
            label="Enter your password"
            counter
            @click:append="showPw = !showPw"
          ></v-text-field>
        </v-card-text>
        <v-card-text>
          <div class="red--text lightgrey text-center">
            {{ message }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            class="mr-4"
            @click="reset"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            type="submit"
          >
            Login
          </v-btn>
          <v-btn
            color="primary"
            type="button"
            @click="createAccount"
          >
            Create Account
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    
    <v-snackbar
      v-model="snackbar"
    >
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  // import * as api from '@/functions/api.js'
  import { Auth, Firestore } from '@/database/config/firebase'
  import store from '@/store'

  export default {
    name: 'SectionMap',
    data: () => ({
      valid: true,
      password: '',
      snackbar: false,
      snackbarMessage: '',
      showPw: false,
      loginError: null,
      rules: {
        required: v => !!v || 'Password is required',
        min: v => (v && v.length >= 6) || 'Name must be at least 8 characters',
      },
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
    }),
    provide: {
      heading: { align: 'center' },
    },
    computed: {
      message () {
        return this.loginError || this.$route.query.message || ''
      },
    },
    mounted () {
      this.initialize()
    },
    methods: {
      initialize () {
        // console.log('to', this.$route.query.returnPath)
      },
      validate () {
        this.$refs.form.validate()
      },
      reset () {
        this.$refs.form.reset()
      },
      createAccount () {
        this.$router.push('/register').catch(err => {
          console.log('error redirecting : ', err)
        })
      },
      redirectPortal () {
        this.$router.push(this.$route.query.returnPath ? this.$route.query.returnPath : '/vizion/dashboard').catch(err => {
          console.log('error redirecting : ', err)
        })
      },
      loginAs (username, password) {
        this.email = username
        this.password = password
        this.login()
      },
      login (e) {
        this.validate()
        if (this.valid) {
          Auth.signInWithEmailAndPassword(this.email, this.password)
            .then((user) => {
              this.snackbarMessage = 'Logging In.'
              this.snackbar = true
              const FireBaseUsers = Firestore.collection('users')
              // console.log('user', user.user)
              // console.log('uid', user.user.uid)
              if (user.user && user.user.uid) {
                FireBaseUsers.doc(user.user.uid)
                  .get()
                  .then((doc) => {
                    const thisUser = doc.data()
                    thisUser.id = doc.id
                    thisUser.displayName = user.user.displayName
                    thisUser.email = user.user.email
                    store.dispatch('fetchUser', thisUser)
                    setTimeout(this.redirectPortal(), 1000)
                  })
              }
            }).catch(error => {
              console.log('error', error)
              this.snackbarMessage = 'Error Logging In.'
              this.loginError = 'Invalid Email Address/Password'
              this.snackbar = true
            })
        }
      },
    },
  }
</script>
